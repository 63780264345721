import "./libs.bundle.js";
import "./main_ui/class.js";
import "./main_ui/polyfill.js";
import "./main_ui/provide.js";
import "./main_ui/translate.js";
import "./main_ui/form/formatters.js";
import "./main_ui/format.js";
import "./main_ui/utils/number_format.js";
import "./main_ui/utils/utils.js";
import "./main_ui/utils/common.js";
import "./main_ui/ui/messages.js";
import "./main_ui/utils/pretty_date.js";
import "./main_ui/utils/datetime.js";
import "./main_ui/microtemplate.js";
import "./main_ui/query_string.js";

import "./main_ui/upload.js";

import "./main_ui/model/meta.js";
import "./main_ui/model/model.js";
import "./main_ui/model/perm.js";

import "./bootstrap-4-web.bundle";

import "../../website/js/website.js";
import "./main_ui/socketio_client.js";
